import React from "react";
const Numbers = ({ filteredPersons, deletePersonHandler }) => {
  return (
    <>
      <h2>Numbers</h2>
      {filteredPersons.map((person) => (
        <p key={person.id}>
          {person.name} {person.number}{" "}
          <button
            id={person.id}
            name={person.name}
            onClick={(e) => deletePersonHandler(e)}
          >
            Delete
          </button>
        </p>
      ))}
    </>
  );
};

export default Numbers;
