import { useEffect, useState } from "react";
import Numbers from "./Numbers";
import Filter from "./Filter";
import PersonForm from "./PersonForm";
import phonebookService from "./services/phonebook";
import Notification from "./Notification";

// Tie .then and .catch to all phonebookService functions

const App = () => {
  const [persons, setPersons] = useState([]);
  const [newName, setNewName] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [searchName, setSearchName] = useState("");
  const [filteredPersons, setFilteredPersons] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [success, setSuccess] = useState(true);

  useEffect(() => {
    phonebookService.getAll().then((p) => {
      setPersons(p);
      setFilteredPersons(p);
    });
  }, []);

  const messageHandler = (message) => {
    // Display a notification for two seconds for the addedName
    setNotificationMessage(message);
    setTimeout(() => {
      setNotificationMessage("");
    }, 2000);
  };

  const deletePersonHandler = (e) => {
    const deletedName = e.currentTarget.name;
    if (window.confirm(`Delete ${e.currentTarget.name}?`)) {
      phonebookService
        .deleteperson(e.currentTarget.id)
        .then((e) => {
          messageHandler(`Deleted ${deletedName}`);
          return setSuccess(true);
        })
        .catch((e) => {
          messageHandler(
            `Information of ${deletedName} has already been removed from the server`
          );
          return setSuccess(false);
        });
      setPersons(persons.filter((p) => p.id.toString() !== e.currentTarget.id));
      setFilteredPersons(
        filteredPersons.filter((p) => p.id.toString() !== e.currentTarget.id)
      );
    }
  };

  const addName = (event) => {
    event.preventDefault();
    const person = {
      name: newName,
      number: newNumber,
    };
    // Check name already exists
    if (
      persons.some((p) => p.name.toLowerCase() === person.name.toLowerCase())
    ) {
      // If it already exists, confirm overwrite with new number
      if (
        window.confirm(
          `${newName} is already added to phonebook, replace the old number with a new one?`
        )
      ) {
        // Write better code for returning the ID of the matched name
        const id = persons.filter((p) => {
          return p.name.toLowerCase() === person.name.toLowerCase();
        })[0].id;
        // Overwrite the number for persons and filteredPersons
        phonebookService
          .update(id, person)
          .then((returnedp) => {
            setPersons(persons.map((p) => (p.id !== id ? p : returnedp)));
            setFilteredPersons(
              persons
                .map((p) => (p.id !== id ? p : returnedp))
                .filter((p) =>
                  p.name.toLowerCase().includes(searchName.toLowerCase())
                )
            );
          })
          .then((e) => {
            messageHandler(`Updated ${person.name}`);
            return setSuccess(true);
          })
          .catch((e) => {
            messageHandler(`${e.response.data.error}`);
            return setSuccess(false);
          });
      }
    } else {
      // Otherwise, just add the name
      phonebookService
        .create(person)
        .then((returnedp) => {
          setPersons(persons.concat(returnedp));
          setFilteredPersons(
            persons
              .concat(returnedp)
              .filter((p) =>
                p.name.toLowerCase().includes(searchName.toLowerCase())
              )
          );
        })
        .then((e) => {
          messageHandler(`Added ${person.name}`);
          return setSuccess(true);
        })
        .catch((e) => {
          messageHandler(`${e.response.data.error}`);
          return setSuccess(false);
        });
      setNewName("");
      setNewNumber("");
    }
  };

  const handleAddName = (event) => {
    setNewName(event.target.value);
  };

  const handleAddNumber = (event) => {
    setNewNumber(event.target.value);
  };

  const handleSearchName = (event) => {
    setSearchName(event.target.value);
    if (event.target.value === "") {
      setFilteredPersons(persons);
    } else {
      setFilteredPersons(
        persons.filter((p) =>
          p.name.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <h2>Phonebook</h2>
      <Notification
        success={success}
        notificationMessage={notificationMessage}
      />
      <Filter searchName={searchName} handleSearchName={handleSearchName} />
      <h2>Add a new</h2>
      <PersonForm
        addName={addName}
        handleAddName={handleAddName}
        newNumber={newNumber}
        handleAddNumber={handleAddNumber}
        newName={newName}
      />
      <Numbers
        deletePersonHandler={deletePersonHandler}
        filteredPersons={filteredPersons}
      />
    </>
  );
};

export default App;
